import { useCallback, useContext, useState } from "react"
import useError from "../useError"
import { supabase } from "../../api"
import { SessionContext } from "../../contexts/SessionContext"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"
import { Session } from "@supabase/supabase-js"

async function hasNewWordlistPermission(session: Session) {
    const countPromise = supabase
        .from('wordlist')
        .select('*', { count: 'exact', head: true })
        .eq('user_id', session.user.id)
    const limitPromise = getRolePermissionLimit(session, 'wordlist.creation')

    const [countResult, limitResult] = await Promise.all([countPromise, limitPromise])
    const { count, error: fetchCountError } = countResult
    const { limit, error: fetchLimitError } = limitResult

    if (fetchCountError) {
        return { error: fetchCountError }
    }
    if (fetchLimitError) {
        return { error: fetchLimitError }
    }

    return { result: !limit || (count ?? 0) < limit }
}

export default function useOpenNewWordlistModal(setUpgradeModalIsOpen: (value: boolean) => void) {
    const { handleError } = useError()
    const [isOpen, setIsOpen] = useState(false)
    const { session } = useContext(SessionContext)

    const tryOpenNewWordlistModal = useCallback(async () => {
        const { result: hasPermission, error } = await hasNewWordlistPermission(session)
        if (error) {
            handleError(error)
            return
        }

        if (hasPermission) {
            setIsOpen(true)
            return
        }

        setUpgradeModalIsOpen(true)
    }, [handleError, session, setUpgradeModalIsOpen])

    return {
        tryOpenNewWordlistModal,
        isOpen,
        setIsOpen
    }
}
