import { User } from '@supabase/supabase-js'
import { Box, Button, Link, Typography } from '@mui/material'
import { Report, Info } from '@mui/icons-material'
import React, { useState } from 'react'
import ReportModal from './modals/ReportModal'
import UserAccountOptionsButtonMenu from './UserAccountOptionsMenu'

export default function Header({ user }: { user: User | undefined }) {
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [isIssue, setIsIssue] = useState<boolean>(false)

    return (
        <React.Fragment>
            <Box width="auto" columnGap={2} display="flex" paddingX={3} paddingY={1} bgcolor="background.header" >
                <Link href="/" display="flex" color="text.primary" sx={{ textDecoration: "none" }}>
                    <Box component="img" width="2.5rem" height="2.5rem" src="/logo192.png" alt="logo" />
                    <Typography component='h3' display='inline' marginLeft={1} fontSize={20} fontWeight='bold' alignContent='center' >
                        Kanji Puzzle
                    </Typography>
                </Link>
                <Box alignContent='center' marginX={2} display='flex' columnGap={3}>
                    {/* {renderNavLink("Study", "/study")} */}
                    {renderNavLink("Browse Wordlists", "/wordlists")}
                    {renderNavLink("My Wordlists", "/wordlists?filter=user-created")}
                    {renderNavLink("Become a Tester", "/tester-signup")}
                </Box>
                <Box display={"flex"} flex={1} />
                <Button variant="outlined" onClick={() => { setModalOpen(true); setIsIssue(true) }} startIcon={<Report />}>
                    Report an issue
                </Button>
                <Button variant="outlined" onClick={() => { setModalOpen(true); setIsIssue(false) }} startIcon={<Info />}>
                    Submit a suggestion
                </Button>
                {/* <LightDarkButton /> */}
                {user && <UserAccountOptionsButtonMenu />}
            </Box>
            <ReportModal open={modalOpen} onClose={() => setModalOpen(false)} isIssue={isIssue} />
        </React.Fragment>
    )
}

const renderNavLink = (text: string, destination: string) => {
    return (
        <Link href={destination} fontWeight='bold' alignContent='center' underline='none' sx={
            {
                color: "text.link",
                "&:hover": { color: "text.linkHover" }
            }
        }>
            {text}
        </Link>)
}