import { More } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import TranslationsModal from "./TranslationsModal";

export type Translation = {
    text: string
    index: number
}

export default function TranslationView({ translations }: { translations: Translation[] }) {
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const primaryTranslationText = translations.find(t => t.index === 0)?.text ?? "[Word translation not found!]"

    return <>
        <Stack direction='row' justifyContent='center' columnGap={2}>
            <Typography variant="h3" textAlign="center">
                {primaryTranslationText}
            </Typography>
            {translations.length > 1 && <>
                <Button onClick={() => setModalIsOpen(true)} startIcon={<More />} />
                <TranslationsModal translations={translations} open={modalIsOpen} onClose={() => setModalIsOpen(false)} />
            </>
            }
        </Stack>
    </>
}
