import { supabase } from "../api"
import { Kanji, kanjiQuery } from "../types/Kanji"
import { ChallengeData, Primitive, PrimitivesChallengeData, StandaloneChallengeData } from "../types/study/ChallengeData"
import { Positions } from "../types/study/PrimitiveChallengeTypes"
import { WordlistContent } from "../types/wordlist/Wordlist"
import { HIRAGANA_END, HIRAGANA_START, KATAKANA_END, KATAKANA_START, isHiragana, isKanji, isKatakana } from "../utils/charUtils"

const TOTAL_CHARS_COUNT = 6

function getRandomInt(min: number, max: number): number {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1)) + min
}

function getRandomChars(min: number, max: number, exclude: number[], count: number) {
    if (max - min < count)
        return { error: new Error("Tried to get " + count + " random chars for a range of only " + (max - min) + "!") }

    const result: number[] = []
    for (var i = 0; i < count; i++) {
        var randomInt
        while (!randomInt || exclude.includes(randomInt)) {
            randomInt = getRandomInt(min, max)
        }
        exclude.push(randomInt)
        result.push(randomInt)
    }
    return { randomChars: result }
}

function getPrimitives(kanji: Kanji) {
    const primitives = kanji.kanji_primitive.map(p => {
        const position: Positions = Positions[p.position.toUpperCase() as keyof typeof Positions]
        return { char: p.primitive, position: position } as Primitive
    })

    const invalidPosition = primitives.find(p => !p.position)
    if (invalidPosition)
        return { error: new Error("Invalid position found for primitive " + invalidPosition.char + " in character " + kanji.id + "!") }

    return { primitives }
}

async function generateChallenge(char: number, position: number) {
    if (isHiragana(char)) {
        const { randomChars: falseChars, error } = getRandomChars(HIRAGANA_START, HIRAGANA_END, [char], TOTAL_CHARS_COUNT - 1)
        if (error)
            return { error }
        const challenge = {
            char: char,
            position: position,
            falseChars: falseChars
        } as StandaloneChallengeData
        return { challenge }
    }

    if (isKatakana(char)) {
        const { randomChars: falseChars, error } = getRandomChars(KATAKANA_START, KATAKANA_END, [char], TOTAL_CHARS_COUNT - 1)
        if (error)
            return { error }
        const challenge = {
            char: char,
            position: position,
            falseChars: falseChars
        } as StandaloneChallengeData
        return { challenge }
    }

    if (isKanji(char)) {
        const { data: kanji, error: postgresError } = await kanjiQuery().eq("id", char).single()
        if (postgresError)
            return { error: postgresError }

        const { primitives, error: primitiveError } = getPrimitives(kanji)

        if (!kanji.has_extra_strokes && kanji.kanji_primitive.length > 1 && !primitiveError) {
            const { data: svg, error: postgresError } = await supabase.from('kanji_svg').select().eq("id", kanji.id).single()
            if (postgresError)
                return { error: postgresError }

            const { data: falsePrimitives, error } =
                await supabase.from('kanji_distinct_primitive_ids_random_order')
                    .select()
                    .not('primitive', 'in', "(" + primitives.map(p => p.char).join(",") + ")")
                    .limit(TOTAL_CHARS_COUNT - primitives.length)
            if (error)
                return { error }

            const challenge = {
                char: char,
                position: position,
                kanjiSvg: svg.svg_data,
                falsePrimitives: falsePrimitives.map(p => p.primitive),
                primitives: primitives
            } as PrimitivesChallengeData
            return { challenge }
        }

        const { data: falseKanji, error } =
            await supabase.from('kanji_random_order')
                .select("id")
                .eq('has_extra_strokes', true)
                .not('id', 'eq', char)
                .limit(TOTAL_CHARS_COUNT - 1)
        if (error)
            return { error }

        const challenge = {
            char: char,
            position: position,
            falseChars: falseKanji.map(k => k.id)
        } as StandaloneChallengeData
        return { challenge, warning: primitiveError }
    }

    return {}
}

export async function generateChallenges(wordlistContent: WordlistContent) {
    const word = wordlistContent.dictionary_word?.word ?? wordlistContent.dictionary_reading.reading
    const challenges: ChallengeData[] = []
    const warnings = []

    for (var i = 0; i < word.length; i++) {
        const char = word.codePointAt(i)
        if (!char)
            return { error: new Error("Error with the word " + word + "! Character in position " + i + ".") }

        const { challenge, error, warning } = await generateChallenge(char, i)
        if (error)
            return { error }
        if (warning)
            warnings.push(warning)

        if (challenge)
            challenges.push(challenge)
    }

    return { challenges, warnings }
}
