import { useEffect, useMemo, useState } from "react";
import { debounce } from "@mui/material";
import { supabase } from "../../api";
import { isNumeric, containsJapanese } from "../../utils/charUtils";
import useError from "../useError";
import { PostgrestError } from "@supabase/supabase-js";
import { DictionaryTranslationSearchResult, DictionaryWordSearchResult } from "../../types/dictionary/DictionarySearchResult";

async function getSearchResults(
    input: string,
    callback: (results?: readonly DictionaryTranslationSearchResult[] | readonly DictionaryWordSearchResult[]) => void,
    handlePostgresError: (error: PostgrestError) => void
) {
    if (isNumeric(input))
        return

    if (containsJapanese(input)) {
        const { data, error } = await supabase.rpc('query_dictionary_word', { search_query: input }).limit(50)
        if (error) {
            handlePostgresError(error)
            return
        }

        callback(data)
        return
    }

    const { data, error } = await supabase.rpc('query_dictionary_translation', { search_query: input }).limit(50)
    if (error) {
        handlePostgresError(error)
        return
    }

    callback(data)
}

export default function useWordSearch() {
    const [inputValue, setInputValue] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [searchOptions, setSearchOptions] = useState<readonly DictionaryTranslationSearchResult[] | readonly DictionaryWordSearchResult[]>([])
    const { handleError } = useError()

    const fetch = useMemo(
        () =>
            debounce(
                (
                    input: string,
                    callback: (results?: readonly DictionaryTranslationSearchResult[] | readonly DictionaryWordSearchResult[]) => void,
                ) => getSearchResults(input, callback, handleError),
                400,
            ),
        [handleError],
    )

    useEffect(() => {
        if (inputValue === "") {
            setSearchOptions([])
            setIsLoading(false)
            return undefined
        }

        setIsLoading(true)
        fetch(inputValue, (results?: readonly DictionaryTranslationSearchResult[] | readonly DictionaryWordSearchResult[]) => {
            setSearchOptions(results ?? [])
            setIsLoading(false)
        }

        )

    }, [inputValue, fetch])

    return { isLoading, searchOptions, inputValue, setInputValue }
}
