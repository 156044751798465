import { Box } from "@mui/material"
import { useDrag } from "react-dnd"
import { DraggableData } from "../../types/study/PrimitiveChallengeTypes"

function renderDraggable(isDragging: boolean, content: string) {
    const opacity = isDragging ? "action.activatedOpacity" : 1
    return (
        <Box border="2px solid" borderColor="text.primary" borderRadius="5px" margin="5px" sx={{ cursor: "move", opacity: opacity }}>
            <Box fontSize="54px" margin="10px">
                {content}
            </Box>
        </Box>
    )
}

export default function Draggable({ data }: { data: DraggableData }) {
    const [collectedProps, drag] = useDrag(() => ({
        type: 'draggable',
        item: data,
        // end: (_item, monitor) => {

        // },
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        })
    }))

    return (
        <Box ref={drag}>
            {renderDraggable(collectedProps.isDragging, String.fromCodePoint(data.primitive))}
        </Box>
    )
}
