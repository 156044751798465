import { DndProvider } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import DropAreaContainer from './DropAreaContainer'
import { PrimitivesChallengeData } from '../../types/study/ChallengeData'
import { Box } from '@mui/material'
import Draggable from './Draggable'
import { DraggableData } from '../../types/study/PrimitiveChallengeTypes'
import usePrimitivesChallenge from '../../hooks/study/usePrimitivesChallenge'
import { PrimitivesChallengeContext } from '../../contexts/PrimitivesChallengeContext'
import { useContext } from 'react'
import { StudyContext } from '../../contexts/StudyContext'
import TranslationView, { Translation } from './TranslationView'

function renderDraggable(data: DraggableData) {
    return (
        <Draggable key={data.id} data={data} />
    )
}

export default function PrimitivesChallenge(
    { translations, challenge, children }: { translations: Translation[], challenge: PrimitivesChallengeData, children: JSX.Element }
) {
    const studyContext = useContext(StudyContext)
    const { displayedPositions, dropPrimitive, draggables } = usePrimitivesChallenge(
        challenge.primitives, challenge.falsePrimitives, studyContext.continueAction, studyContext.mistakeAction
    )

    return (
        <PrimitivesChallengeContext.Provider value={{ handleDrop: dropPrimitive }}>
            <DndProvider options={HTML5toTouch}>
                <Box display="flex" flexDirection="column" rowGap="40px">
                    {children}
                    <TranslationView translations={translations} />
                    <DropAreaContainer kanjiSvgString={challenge.kanjiSvg} shownPositions={displayedPositions} primitives={challenge.primitives} />
                    <Box display="flex" flexDirection="row" flex={1} flexWrap="wrap" justifyContent="center" columnGap="20px" zIndex="fab">
                        {draggables.map(d => renderDraggable(d))}
                    </Box>
                </Box>
            </DndProvider>
        </PrimitivesChallengeContext.Provider>
    )
}
