import { Box } from '@mui/material'
import XMLParser, { XMLElement } from 'react-xml-parser'
import { Positions } from '../../types/study/PrimitiveChallengeTypes'
import { checkPositionMatch } from '../../helpers/PrimitivePositionHelper'

const emptySvg = "<svg />"

function setWidthHeight(svg: XMLElement) {
    svg.attributes["width"] = "100%"
    svg.attributes["height"] = "100%"
}

function getModifiedSvg(svgString: string | undefined, shownPositions: Positions[], showStrokeNumbers: boolean): string {
    if (!svgString || svgString.length === 0) return emptySvg

    var svg = new XMLParser().parseFromString(svgString)
    if (!svg) return emptySvg
    if (svg.children.length < 1) return emptySvg

    setWidthHeight(svg)

    var mainIndex: number | undefined
    var strokeNumbersIndex: number | undefined
    for (var i = 0; i < svg.children.length; i++) {
        if (svg.children[i]['attributes']['id'].includes("StrokePaths")) {
            mainIndex = i
        }
        else if (svg.children[i]['attributes']['id'].includes("StrokeNumbers")) {
            strokeNumbersIndex = i
        }
    }

    if (mainIndex === undefined || strokeNumbersIndex === undefined)
        return emptySvg

    if (!showStrokeNumbers) {
        delete svg.children[strokeNumbersIndex]
    }

    for (var j = 0; j < svg.children[mainIndex].children[0].children.length; j++) {
        const position = Positions[svg.children[mainIndex].children[0].children[j]['attributes']['ns1:position'].toUpperCase() as keyof typeof Positions]
        if (shownPositions.filter(p => checkPositionMatch(position, p)).length === 0)
            delete svg.children[mainIndex].children[0].children[j]
    }

    return new XMLParser().toString(svg)
}

export default function Kanji({ svgString, shownPositions, showStrokeNumbers }: { svgString: string | undefined, shownPositions: Positions[], showStrokeNumbers: boolean }) {
    return (<Box position="absolute" dangerouslySetInnerHTML={{ __html: getModifiedSvg(svgString, shownPositions, showStrokeNumbers) }} />)
}
