import { useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { StudyContext } from '../../contexts/StudyContext'
import TranslationView, { Translation } from './TranslationView'

export default function CompletedChallenge({ word, reading, translations }: { word: string | null, reading: string, translations: Translation[] }) {
    const studyContext = useContext(StudyContext)
    const headerText = word ?? reading
    const showReading = word && reading

    return (
        <Box display="flex" flexDirection="column" rowGap="40px">
            <Typography variant="h3" textAlign="center">{headerText}</Typography>
            {showReading &&
                <Typography textAlign="center">{reading}</Typography>
            }
            <TranslationView translations={translations} />
            <Box display="flex" justifyContent="center">
                <Button variant="contained" onClick={studyContext.continueAction}>Continue</Button>
            </Box>
        </Box>
    )
}
