import { useCallback, useContext, useEffect, useState } from "react";
import { PostgrestError } from "@supabase/supabase-js";
import { WordlistSnapshot, wordlistSnapshotQuery } from "../../types/wordlist/WordlistSnapshot";
import { SessionContext } from "../../contexts/SessionContext";
import useError from "../useError";
import { useNavigate } from "react-router-dom";

async function getWordlistSnapshots(publicOnly: boolean, subscriptionsOnly: boolean, userId?: string, limit?: number, searchQuery?: string) {
    let query = wordlistSnapshotQuery()
    if (publicOnly)
        query = query.eq('is_public', true)
    if (subscriptionsOnly)
        query = query.not("wordlist_subscription", 'is', "NULL")
    if (userId)
        query = query.eq('user_id', userId)
    if (searchQuery)
        query = query.textSearch('name', searchQuery, { type: 'websearch' })
    if (limit)
        query = query.limit(limit)
    return await query
}

function getTotalWordCount(wordlistSnapshot: WordlistSnapshot) {
    return wordlistSnapshot.wordlist_level.map(c => c.wordlist_content.find(c => c)?.count ?? 0).reduce((acc, cur) => acc + cur, 0)
}

export default function useWordlistSnapshots(subscriptionsOnly: boolean, publicOnly: boolean, ownerOnly: boolean, limit?: number, searchQuery?: string) {
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [wordlistSnapshots, setWordlistSnapshots] = useState<WordlistSnapshot[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<PostgrestError>()
    const navigate = useNavigate()

    useEffect(() => {
        async function doAsync() {
            const { data, error } =
                await getWordlistSnapshots(publicOnly ?? true, subscriptionsOnly, ownerOnly ? session.user.id : undefined, limit, searchQuery)
            if (error) {
                setError(error)
                handleError(error)
                return
            }

            setWordlistSnapshots(data)
            setIsLoading(false)
        }

        doAsync()
    }, [handleError, limit, ownerOnly, publicOnly, searchQuery, session.user.id, subscriptionsOnly])

    const viewWordlist = useCallback((wordlistId: string) => {
        navigate("/wordlist/" + wordlistId)
    }, [navigate])

    return { wordlistSnapshots, setWordlistSnapshots, isLoading, error, getTotalWordCount, viewWordlist }
}
