import { IconButton, Modal, Stack, Typography } from "@mui/material";
import { AddBox, Search } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export default function AddWordlistModal(
    { open, onClose, tryOpenNewWordlistModal }: { open: boolean, onClose: () => void, tryOpenNewWordlistModal: () => void }
) {
    const navigate = useNavigate()

    return <Modal open={open} onClose={onClose}>
        <Stack sx={stackStyle} rowGap={1}>
            <IconButton sx={{ borderRadius: 2, paddingY: 4 }} onClick={() => navigate("/wordlists")}>
                <Search color='primary' />
                <Typography color="text.primary">Browse wordlists</Typography>
            </IconButton>
            <Typography textAlign='center'>or</Typography>
            <IconButton sx={{ borderRadius: 2, paddingY: 4 }} onClick={tryOpenNewWordlistModal}>
                <AddBox color='primary' />
                <Typography color="text.primary">Create a new wordlist</Typography>
            </IconButton>
        </Stack>
    </Modal>
}

const stackStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
}
