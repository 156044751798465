import { Box, Grid, Tooltip, Typography } from "@mui/material";
import { StudyInformation } from "../../types/study/StudyInformation";
import { HighlightOff } from "@mui/icons-material";

function renderColoredNumberWithTooltip(tooltip: string, color: string, value: number, isActive: boolean) {
    return <Tooltip title={tooltip}>
        <Typography textAlign='center' color={color} fontWeight={isActive ? 'bold' : undefined} >{value}</Typography>
    </Tooltip>
}

export default function StudyInformationDisplay({ studyInformation }: { studyInformation: StudyInformation }) {
    const { nonDueWordsCount, dueWordsCount, newWordsCount, activeChallengeWordStatus, lastReviewedText, mistakes } = studyInformation

    return <Box display='flex' flexDirection='row' >
        <Box flex={1} display='flex' justifyContent='center' >
            {lastReviewedText ?
                <Tooltip title="Word last seen">
                    <Typography>{lastReviewedText}</Typography>
                </Tooltip>
                :
                <Typography color="#0000CC" fontSize={18}>*New</Typography>
            }
        </Box>
        <Box display='flex' flexDirection='row' columnGap={2} justifyContent='center' sx={{ userSelect: 'none' }}>
            {renderColoredNumberWithTooltip("Words already reviewed", "#00CC00", nonDueWordsCount, activeChallengeWordStatus === 'nonDueWord')}
            {renderColoredNumberWithTooltip("Due words remaining", "#CC0000", dueWordsCount, activeChallengeWordStatus === 'dueWord')}
            {renderColoredNumberWithTooltip("New words remaining", "#0000CC", newWordsCount, activeChallengeWordStatus === 'newWord')}
        </Box>
        <Box flex={1} display='flex' justifyContent='center'>
            <Grid container justifyContent='center' maxWidth={200}>
                {Array.from(Array(mistakes)).map((_, i) => (
                    <Grid item key={i} color="error.dark" xs={2}>
                        <HighlightOff />
                    </Grid>
                ))}
            </Grid>
        </Box>
    </Box>
}
