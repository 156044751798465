import { supabase } from "../../api"
import { useCallback, useContext } from "react"
import useError from "../useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"
import { Wordlist, WordlistContent, WordlistLevel } from "../../types/wordlist/Wordlist"
import { Tables } from "../../types/supabase"

type WordlistTable = Tables<'wordlist'>
type WordlistContentTable = Tables<'wordlist_content'>

async function updateWordlistInfo(wordlist: WordlistTable) {
    const { error: updateError } = await supabase.from('wordlist').update(wordlist).eq('id', wordlist.id)
    if (updateError)
        return { error: updateError }

    return {}
}

function contentToTableContent(wordlistContent: WordlistContent, wordlistLevelId: number) {
    let result = {
        index: wordlistContent.index,
        word_id: wordlistContent.dictionary_word?.id,
        reading_id: wordlistContent.dictionary_reading.id,
        meaning_id: wordlistContent.dictionary_meaning.id,
        wordlist_level_id: wordlistLevelId
    } as WordlistContentTable
    if (wordlistContent.id)
        result.id = wordlistContent.id
    return result
}

function wordlistContentTableShallowEquals(wordlistContent: WordlistContent, wordlistContentTable: WordlistContentTable) {
    return (wordlistContent.dictionary_word?.id ?? null) === wordlistContentTable.word_id &&
        wordlistContent.dictionary_reading.id === wordlistContentTable.reading_id &&
        wordlistContent.dictionary_meaning.id === wordlistContentTable.meaning_id &&
        wordlistContent.index === wordlistContentTable.index
}

function refreshWordlistWithInsertedIds(
    returnedInsertedItems: WordlistContentTable[],
    wordlistLevel: WordlistLevel,
    updateWordlistLevel: (wordlistLevel: WordlistLevel) => void
) {
    const contentWithIdsAdded = wordlistLevel.wordlist_content.filter(c => !c.id).map(c => ({
        ...c,
        id: returnedInsertedItems.find(r => wordlistContentTableShallowEquals(c, r))?.id
    } as WordlistContent))
    updateWordlistLevel({
        ...wordlistLevel,
        wordlist_content: [
            ...wordlistLevel.wordlist_content.filter(c => c.id),
            ...contentWithIdsAdded
        ]
    } as WordlistLevel)
}

async function updateWordlistContent(
    wordlistContent: WordlistContentTable[],
    contentToDeleteIds: number[],
    returnedInsertedItemsCallback: (returnedInsertedItems: WordlistContentTable[]) => void
) {
    const { error: deleteError } = await supabase.from('wordlist_content').delete().in('id', contentToDeleteIds)
    if (deleteError)
        return { error: deleteError }

    const updatedItems = wordlistContent.filter(c => c.id)
    // TODO: update when supabase supports bulk updates
    updatedItems.forEach(async c => {
        const { error: updateError } = await supabase.from('wordlist_content').update(c).eq('id', c.id!)
        if (updateError)
            return { error: updateError }
    })

    const newItems = wordlistContent.filter(c => !c.id)
    const { data, error: insertError } = await supabase.from('wordlist_content').insert(newItems).select()
    if (insertError)
        return { error: insertError }

    returnedInsertedItemsCallback(data)
    return {}
}

export default function useWordlistSave(
    wordlist: Wordlist | undefined,
    contentToDelete: WordlistContent[],
    resetWordlistEditStates: (() => void)[],
    wordlistLevel: WordlistLevel | undefined,
    updateWordlistLevel: (wordlistLevel: WordlistLevel) => void
) {
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)

    const updateLevelState = useCallback(async (returnedInsertedContent: WordlistContentTable[]) => {
        if (wordlistLevel)
            refreshWordlistWithInsertedIds(returnedInsertedContent, wordlistLevel, updateWordlistLevel)
    }, [updateWordlistLevel, wordlistLevel])

    const handleSubmit = useCallback(async () => {
        if (!wordlist)
            return

        const { wordlist_level, ...wordlistSurfaceData } = wordlist
        const { error: wordlistUpdateError } = await updateWordlistInfo(wordlistSurfaceData)
        if (wordlistUpdateError) {
            handleError(wordlistUpdateError)
            return
        }

        //TODO: test saving multiple levels at once
        const allContent = wordlist_level.flatMap(
            level => level.wordlist_content.map(
                c => contentToTableContent(c, level.id)
            )
        )

        const { error: wordlistContentUpdateError } = await updateWordlistContent(allContent, contentToDelete.map(c => c.id), updateLevelState)
        if (wordlistContentUpdateError) {
            handleError(wordlistContentUpdateError)
            return
        }

        snackbarSuccess("Wordlist updated.")
        resetWordlistEditStates.forEach(action => { action() })
    }, [wordlist, contentToDelete, updateLevelState, snackbarSuccess, resetWordlistEditStates, handleError])

    return { handleSubmit }
}
