import { Positions } from "../types/study/PrimitiveChallengeTypes";

export function getAcceptableCardinalPositions(position: Positions) {
    switch (position) {
        case Positions.TARE:
            return [Positions.TOP, Positions.LEFT]
        case Positions.TAREC:
            return [Positions.BOTTOM, Positions.RIGHT]
        case Positions.NYO:
            return [Positions.BOTTOM, Positions.LEFT]
        case Positions.NYOC:
            return [Positions.TOP, Positions.RIGHT]
        case Positions.KAMAE:
            return [Positions.TOP, Positions.RIGHT]
        case Positions.KAMAEC:
            return [Positions.BOTTOM, Positions.LEFT]
    }

    return [position]
}

export function checkPositionMatch(primitivePosition: Positions, cardinalPosition: Positions) {
    return getAcceptableCardinalPositions(primitivePosition).includes(cardinalPosition)
}
