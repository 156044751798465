import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { FormEvent, useContext, useState } from "react";
import { supabase } from "../../api";
import useError from "../../hooks/useError";
import { SnackbarContext } from "../../contexts/SnackbarContext";

export default function ReportModal({ open, onClose, isIssue }: { open: boolean, onClose: () => void, isIssue: boolean }) {
    const [returnEmail, setReturnEmail] = useState<string>("")
    const [messageText, setMessageText] = useState<string>("")
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const { error } = await supabase.functions.invoke('resend', {
            body: { textBody: messageText, returnEmail: returnEmail, isIssue: isIssue },
        })
        if (error)
            handleError(error)
        else {
            snackbarSuccess((isIssue ? "Issue" : "Suggestion") + " successfully sent.")
            onClose()
        }
    }

    const title = isIssue ? "Report an issue" : "Submit a suggestion"
    const subtitle = isIssue ? "Bringing attention to the bugs and errors in this project helps to keep it improving!" :
        "Your creative suggestions help improve this project significantly!"

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={styles.modalBox} component="form" display="flex" flexDirection="column" rowGap="20px" onSubmit={handleSubmit}>
                <Typography variant="h6" component="h2">{title}</Typography>
                <Typography variant="h6" component="h5">{subtitle}</Typography>
                <TextField
                    id="outlined-basic"
                    label="Your email (Optional)"
                    variant="outlined"
                    onChange={e => setReturnEmail(e.target.value)}
                    inputProps={{ maxLength: 500 }}
                    sx={{ backgroundColor: "grey.50" }}
                />
                <TextField
                    id="outlined-basic"
                    label="Message"
                    variant="outlined"
                    required
                    onChange={e => setMessageText(e.target.value)}
                    inputProps={{ maxLength: 50_000 }}
                    sx={{ backgroundColor: "grey.50" }}
                />
                <Box flex={1} display="flex" flexDirection="column">
                    <Button variant="contained" type="submit">Submit</Button>
                </Box>
            </Box>
        </Modal>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
