import { Box, ListItem, Typography } from "@mui/material";
import { DictionaryTranslationSearchResult, DictionaryWordSearchResult } from "../../types/dictionary/DictionarySearchResult";

export default function WordSearchboxSuggestion(
    { dictionarySearchResult, props }:
        { dictionarySearchResult: DictionaryTranslationSearchResult | DictionaryWordSearchResult, props: React.HTMLAttributes<HTMLLIElement> }
) {
    const japaneseText = dictionarySearchResult.word ? dictionarySearchResult.word : dictionarySearchResult.reading
    return (
        <ListItem {...props}>
            <Box>
                <Typography>
                    {japaneseText}
                </Typography>
                <Typography>
                    {dictionarySearchResult.translation}
                </Typography>
            </Box>
        </ListItem>
    )
}
