import { Typography } from "@mui/material"

export default function PartialWordPreview({ word, reading, charactersShown }: { word: string | null, reading: string, charactersShown: number }) {
    const fullWord = word ?? reading
    const preview = fullWord.split("").map(
        (value, i) => charactersShown > i ? value : " _ "
    ).join("")
    return (
        <Typography variant="h3" textAlign="center">{preview}</Typography>
    )
}
