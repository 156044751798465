import { Box, Container, IconButton, Typography } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import UpgradeModal from '../components/modals/UpgradeModal'
import NewWordlistModal from '../components/modals/NewWordlistModal'
import useOpenNewWordlistModal from '../hooks/wordlist/useOpenNewWordlistModal'
import Loadable from '../components/Loadable'
import Subheader from '../components/Subheader'
import { Fragment } from 'react/jsx-runtime'
import useWordlistSnapshots from '../hooks/wordlist/useWordlistSnapshots'
import WordlistSnapshotList from '../components/wordlist/WordlistSnapshotList'
import AddWordlistModal from '../components/modals/AddWordlistModal'
import { useState } from 'react'
import useUpgradeModal from '../hooks/useUpgradeModal'
import NewWordlistButton from '../components/NewWordlistButton'

export default function Dashboard() {
    const { isOpen: upgradeModalIsOpen, setIsOpen: setUpgradeModalIsOpen } = useUpgradeModal()
    const { wordlistSnapshots, setWordlistSnapshots, isLoading, error, getTotalWordCount, viewWordlist } = useWordlistSnapshots(true, false, false)
    const [addWordlistModalIsOpen, setAddWordlistModalIsOpen] = useState<boolean>(false)
    const { tryOpenNewWordlistModal, isOpen: newWordlistModalIsOpen, setIsOpen: setNewWordlistModalIsOpen } = useOpenNewWordlistModal(setUpgradeModalIsOpen)

    return (
        <Fragment>
            <Subheader title="Dashboard">
                <NewWordlistButton tryOpenNewWordlistModal={tryOpenNewWordlistModal} />
            </Subheader>
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap="20px" margin={4}>
                    <Loadable isLoading={isLoading} error={error}>
                        <WordlistSnapshotList
                            wordlistSnapshots={wordlistSnapshots ?? []}
                            getTotalWordCount={getTotalWordCount}
                            viewWordlist={viewWordlist}
                            setWordlistSnapshots={setWordlistSnapshots}
                            subscribe={null}
                            unsubscribe={null}
                        />
                    </Loadable>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton onClick={() => setAddWordlistModalIsOpen(true)} sx={{ borderRadius: 2 }}>
                        <AddCircle sx={{ fontSize: 50 }} color="success" />
                        <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary', fontSize: '0.875rem' }} >Add a new wordlist</Typography>
                    </IconButton>
                </Box>
                <AddWordlistModal open={addWordlistModalIsOpen} onClose={() => setAddWordlistModalIsOpen(false)} tryOpenNewWordlistModal={tryOpenNewWordlistModal} />
                <NewWordlistModal open={newWordlistModalIsOpen} onClose={() => setNewWordlistModalIsOpen(false)} />
                <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            </Container >
        </Fragment >)
}
