import { Box, Button, Container, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import Subheader from "../components/Subheader";
import useBrowseWordlists from "../hooks/wordlist/useBrowseWordlists";
import Loadable from "../components/Loadable";
import UpgradeModal from "../components/modals/UpgradeModal";
import WordlistSnapshotList from "../components/wordlist/WordlistSnapshotList";
import useOpenNewWordlistModal from "../hooks/wordlist/useOpenNewWordlistModal";
import useUpgradeModal from "../hooks/useUpgradeModal";
import NewWordlistModal from "../components/modals/NewWordlistModal";
import NewWordlistButton from "../components/NewWordlistButton";

export default function BrowseWordlists() {
    const { isOpen: upgradeModalIsOpen, setIsOpen: setUpgradeModalIsOpen } = useUpgradeModal()

    const {
        wordlistSnapshots,
        setWordlistSnapshots,
        isLoading,
        error,
        viewWordlist,
        getTotalWordCount,
        subscribe,
        unsubscribe,
        filterSelectValue,
        handleFilterChange,
        searchString
    } = useBrowseWordlists()

    const { tryOpenNewWordlistModal, isOpen: newWordlistModalIsOpen, setIsOpen: setNewWordlistModalIsOpen } = useOpenNewWordlistModal(setUpgradeModalIsOpen)

    const noWordlistsString = wordlistSnapshots?.length === 0 && (searchString ? "Search returned no results." : "No wordlists found.")
    const noWordlistsTypography = noWordlistsString && <Typography>{noWordlistsString}</Typography>
    return (
        <Fragment>
            <Subheader title="Browse Wordlists">
                <NewWordlistButton tryOpenNewWordlistModal={tryOpenNewWordlistModal} />
            </Subheader>
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap="20px" margin={4}>
                    <Stack component='form' direction='row' gap={4}>
                        <FormControl fullWidth>
                            <InputLabel>Filter</InputLabel>
                            <Select label="Filter" value={filterSelectValue} onChange={handleFilterChange} >
                                <MenuItem value="all">All</MenuItem>
                                <MenuItem value="subscribed">Subscribed</MenuItem>
                                <MenuItem value="user-created">Created by you</MenuItem>
                            </Select>
                        </FormControl>
                        <TextField label="Search input" name="search" defaultValue={searchString} fullWidth sx={{ minWidth: 400, backgroundColor: "grey.50" }} />
                        <Button variant="contained" type="submit">Search</Button>
                    </Stack>
                    <Loadable isLoading={isLoading} error={error}>
                        <WordlistSnapshotList
                            wordlistSnapshots={wordlistSnapshots ?? []}
                            getTotalWordCount={getTotalWordCount}
                            viewWordlist={viewWordlist}
                            setWordlistSnapshots={setWordlistSnapshots}
                            subscribe={subscribe}
                            unsubscribe={unsubscribe}
                        />
                        {noWordlistsTypography}
                    </Loadable>
                </Box>
                <NewWordlistModal open={newWordlistModalIsOpen} onClose={() => setNewWordlistModalIsOpen(false)} />
                <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            </Container>
        </Fragment>
    )
}
