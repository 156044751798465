import { Box } from "@mui/material"
import {
    DragDropContext,
    OnDragEndResponder
} from 'react-beautiful-dnd'
import WordlistEntriesListItem from "./WordlistEntriesListItem"
import { StrictModeDroppable } from "../StrictModeDroppable"
import { WordlistContent } from "../../types/wordlist/Wordlist"

type WordlistContentMeaning = WordlistContent['dictionary_meaning']

export interface WordlistListEntry {
    id: number,
    index: number,
    word: string | null,
    reading: string,
    meaning: WordlistContentMeaning,
    deleteItem: () => void
}

type WordlistEntriesListProps = {
    wordlistListEntries: WordlistListEntry[]
    editMode: boolean,
    onDragEnd: OnDragEndResponder
}

export default function WordlistEntriesList({ wordlistListEntries, editMode, onDragEnd }: WordlistEntriesListProps) {
    const listContent = wordlistListEntries.map(w => (
        <WordlistEntriesListItem key={w.id} wordlistListEntry={w} editMode={editMode} />
    ))

    if (editMode)
        return (
            <DragDropContext onDragEnd={onDragEnd}>
                <StrictModeDroppable droppableId="droppable-list">
                    {provided => (
                        <Box ref={provided.innerRef} {...provided.droppableProps}>
                            {listContent}
                            {provided.placeholder}
                        </Box>
                    )
                    }
                </StrictModeDroppable>
            </DragDropContext>
        )

    return <Box>{listContent}</Box>
}
