import { useCallback, useContext, useState } from "react";
import { supabase } from "../../api";
import { TablesInsert } from "../../types/supabase";
import useError from "../useError";
import { SnackbarContext } from "../../contexts/SnackbarContext"
import { WordlistSnapshot } from "../../types/wordlist/WordlistSnapshot";
import { SessionContext } from "../../contexts/SessionContext";

async function insertUserSubscription(subscription: TablesInsert<'wordlist_subscription'>) {
    return await supabase.from('wordlist_subscription').insert(subscription).select().single()
}

async function deleteUserSubscription(userId: string, wordlistId: string) {
    return await supabase.from('wordlist_subscription').delete().eq('user_id', userId).eq('wordlist_id', wordlistId).select()
}

export default function useWordlistSubscriptions(
    updateSnapshots: (value: (value: WordlistSnapshot[] | undefined) => WordlistSnapshot[] | undefined) => void
) {
    const { session } = useContext(SessionContext)
    const { snackbarSuccess } = useContext(SnackbarContext)
    const { handleError } = useError()
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)

    const subscribe = useCallback(async (wordlistId: string) => {
        const { data: subscription, error: insertSubscriptionError } = await insertUserSubscription({ user_id: session.user.id, wordlist_id: wordlistId })
        if (insertSubscriptionError) {
            if (insertSubscriptionError.code === "42501") {
                setUpgradeModalIsOpen(true)
                return
            }
            handleError(insertSubscriptionError)
            return
        }

        updateSnapshots(prev => {
            if (!prev)
                return prev
            const snapshot = prev.find(p => p.id === subscription.wordlist_id)
            if (!snapshot)
                return prev
            snapshot.wordlist_subscription = [subscription]
            return [...prev.filter(p => p.id !== snapshot.id), snapshot]
        })

        snackbarSuccess("Subscription added.")
    }, [session, updateSnapshots, snackbarSuccess, handleError])

    const unsubscribe = useCallback(async (wordlistId: string) => {
        const { data, error } = await deleteUserSubscription(session.user.id, wordlistId)
        if (error) {
            handleError(error)
            return
        }
        if (data.length < 1) {
            handleError(new Error(`Wordlist subscription delete on wordlist ${wordlistId} for user ${session.user.id} silently failed.`))
            return
        }

        updateSnapshots(prev => {
            if (!prev)
                return prev
            const snapshot = prev.find(p => p.id === wordlistId)
            if (!snapshot)
                return prev
            snapshot.wordlist_subscription = snapshot.wordlist_subscription.filter(s => s.user_id !== session.user.id)
            return [...prev.filter(p => p.id !== snapshot.id), snapshot]
        })

        snackbarSuccess("Subscription removed.")
    }, [session.user.id, updateSnapshots, snackbarSuccess, handleError])

    return { subscribe, unsubscribe, upgradeModalIsOpen, setUpgradeModalIsOpen }
}
