import { QueryData } from "@supabase/supabase-js"
import { supabase } from "../../api";

export type WordlistSnapshot = QueryData<ReturnType<typeof wordlistSnapshotQuery>>[number]
export const wordlistSnapshotQuery = () => supabase.from('wordlist').select(`
    *,
    wordlist_level (
        wordlist_content(count)
    ),
    wordlist_subscription (*)
`)
