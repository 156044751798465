import { useCallback, useContext, useEffect, useState } from "react"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"
import { SessionContext } from "../../contexts/SessionContext"
import { fullWordlistQuery, Wordlist } from "../../types/wordlist/Wordlist"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"

export default function useWordlist(wordlistId: string | undefined) {
    const [wordlist, setWordlist] = useState<Wordlist>()
    const [error, setError] = useState<Error | PostgrestError>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isOwner, setIsOwner] = useState<boolean>(false)
    const { handleError } = useError()
    const { session } = useContext(SessionContext)

    const fetchWordlist = useCallback(async (wordlistId?: string) => {
        if (!wordlistId) {
            const undefinedError = new Error("Wordlist not found! Please verify the URL.")
            handleError(undefinedError)
            setError(undefinedError)
            setIsLoading(false)
            return
        }

        const { limit, error: fetchLimitError } = await getRolePermissionLimit(session, 'wordlist_content.selection')
        if (fetchLimitError) {
            handleError(fetchLimitError)
            setError(fetchLimitError)
            setIsLoading(false)
            return
        }

        let query = fullWordlistQuery().eq('id', wordlistId)
        if (limit)
            query.limit(limit, { referencedTable: "wordlist_level.wordlist_content" })

        const { data: wordlist, error: fetchWordlistError } = await query.order('index', { ascending: true, referencedTable: 'wordlist_level.wordlist_content' })
            .single()
        if (fetchWordlistError) {
            handleError(fetchWordlistError)
            setError(fetchWordlistError)
            setIsLoading(false)
            return
        }

        setWordlist(wordlist)
        setIsOwner(wordlist.user_id === session.user.id)
    }, [handleError, session])

    useEffect(() => {
        async function doAsync() {
            await fetchWordlist(wordlistId)
            setIsLoading(false)
        }

        doAsync()
    }, [handleError, fetchWordlist, wordlistId])

    return { wordlist, setWordlist, isOwner, isLoading, error }
}
