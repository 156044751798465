import { useState, useContext, useCallback, useEffect } from "react"
import { SessionContext } from "../../contexts/SessionContext"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"
import useError from "../useError"
import { DropResult } from "react-beautiful-dnd"
import { WordlistContent, WordlistLevel } from "../../types/wordlist/Wordlist"
import { reorder } from "../../utils/arrayUtils"
import { DictionaryTranslationSearchResult, DictionaryWordSearchResult } from "../../types/dictionary/DictionarySearchResult"
import useWarning from "../useWarning"

function reindexWordlistContent(wordlistContent: WordlistContent[]) {
    return wordlistContent.map((c, i) => ({ ...c, index: i }))
}

function dictionarySearchResultToWordlistContent(searchResult: DictionaryTranslationSearchResult | DictionaryWordSearchResult, index: number) {
    return {
        index: index + 1,
        dictionary_word: {
            id: searchResult.word_id,
            word: searchResult.word,
            index: searchResult.word_index
        },
        dictionary_reading: {
            id: searchResult.reading_id,
            reading: searchResult.reading,
            index: searchResult.reading_index
        },
        dictionary_meaning: {
            id: searchResult.dictionary_meaning_id,
            dictionary_meaning_translation: [{
                id: searchResult.translation_id,
                translation: searchResult.translation,
                index: searchResult.translation_index
            }],
            index: searchResult.meaning_index
        }
    } as WordlistContent
}

function getExistingItem(newWord: DictionaryTranslationSearchResult | DictionaryWordSearchResult, wordlistContent: WordlistContent[]) {
    return wordlistContent.find(c =>
        c.dictionary_word?.id === newWord.word_id &&
        c.dictionary_reading.id === newWord.reading_id &&
        c.dictionary_meaning.id === newWord.dictionary_meaning_id
    )
}

export default function useWordlistLevelEditContent(
    wordlistLevel: WordlistLevel | undefined,
    updateWordlistLevel: (wordlistLevel: WordlistLevel) => void
) {
    const { session } = useContext(SessionContext)
    const { handleWarning } = useWarning()
    const { handleError } = useError()
    const [contentToDelete, setContentToDelete] = useState<WordlistContent[]>([])
    const [contentCreationLimit, setContentCreationLimit] = useState<number | null>()
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        async function doAsync() {
            if (handleError && session) {
                const { limit, error } = await getRolePermissionLimit(session, "wordlist_content.creation")
                if (error) {
                    handleError(error)
                    return
                }

                setContentCreationLimit(limit)
            }
        }

        doAsync()
    }, [handleError, session])

    const resetState = () => {
        setContentToDelete([])
        setUpgradeModalIsOpen(false)
        setIsDirty(false)
    }

    const deleteItem = useCallback((contentToDelete: WordlistContent) => {
        if (!wordlistLevel)
            return

        if (contentToDelete.id)
            setContentToDelete(content => [...content, contentToDelete])

        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: reindexWordlistContent(wordlistLevel.wordlist_content.filter(c => c !== contentToDelete))
        })
        setIsDirty(true)
    }, [updateWordlistLevel, wordlistLevel])

    const addWordToList = useCallback((newWord: DictionaryTranslationSearchResult | DictionaryWordSearchResult | null) => {
        if (!newWord?.id || !wordlistLevel)
            return

        const existingContent = wordlistLevel.wordlist_content
        if (contentCreationLimit && existingContent.length >= contentCreationLimit) {
            setUpgradeModalIsOpen(true)
            return
        }

        if (getExistingItem(newWord, existingContent)) {
            handleWarning(new Error(`The word ${newWord.word ?? newWord.reading} is already in the wordlist.`))
            return
        }

        const highestIndex = existingContent.length < 1 ? -1 : Math.max(...existingContent.map(c => c.index))
        const newContent = dictionarySearchResultToWordlistContent(newWord, highestIndex)

        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: [...existingContent, newContent]
        })

        setIsDirty(true)
    }, [contentCreationLimit, handleWarning, updateWordlistLevel, wordlistLevel])

    const reorderWordlistContent = useCallback((originIndex: number, destinationIndex: number) => {
        if (!wordlistLevel)
            return

        const existingContent = wordlistLevel.wordlist_content
        const reindexedContent = reindexWordlistContent(reorder(existingContent, originIndex, destinationIndex))
        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: reindexedContent
        })
        setIsDirty(true)
    }, [updateWordlistLevel, wordlistLevel])

    const onDragEnd = useCallback(({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return

        reorderWordlistContent(source.index, destination.index)
        setIsDirty(true)
    }, [reorderWordlistContent])

    return { deleteItem, contentToDelete, addWordToList, onDragEnd, isDirty, resetState, upgradeModalIsOpen, setUpgradeModalIsOpen }
}
