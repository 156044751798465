const defaultDifficulty = 0.6
const newDefaultDuration = 1000 // milliseconds
const minCorrectDuration = 1 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
const minIncorrectDuration = 1 * 60 * 1000 // minutes*seconds*milliseconds
const maxIncorrectDuration = 24 * 60 * 60 * 1000 // hours*minutes*secords*milliseconds
const correctThreshold = 0.9

export type SrsData = {
    difficulty: number,
    dueDuration: number,
    lastReviewed: number
}

function clamp(min: number, max: number, value: number) {
    return Math.min(Math.max(min, value), max)
}

export function srsUpdate(confidence: number | null = null, previous: SrsData | null = null): SrsData {
    if (confidence === null || previous === null) {
        return {
            difficulty: defaultDifficulty,
            dueDuration: newDefaultDuration,
            lastReviewed: Date.now()
        } as SrsData
    }

    const isCorrect = confidence > correctThreshold

    const overdueMultiplier = isCorrect ? Math.min(2, getOverdueFactor(previous.dueDuration, previous.lastReviewed)) : 1
    const additionalDifficulty = ((8 - 9 * confidence) / 17) * overdueMultiplier
    const difficultyWeight = 3 - 1.7 * (previous.difficulty + additionalDifficulty)
    const dueDurationMultiplier = isCorrect ? 1 + (difficultyWeight - 1) * overdueMultiplier : 1 / (1 + 3 * previous.difficulty)

    const newDifficulty = clamp(0, 1, previous.difficulty + additionalDifficulty)
    var newDueDuration = previous.dueDuration * dueDurationMultiplier
    if (isCorrect) {
        newDueDuration = Math.max(newDueDuration, minCorrectDuration)
    }
    else {
        newDueDuration = clamp(minIncorrectDuration, maxIncorrectDuration, newDueDuration)
    }

    return {
        difficulty: newDifficulty,
        dueDuration: Math.round(newDueDuration),
        lastReviewed: Date.now()
    } as SrsData
}

export function getOverdueFactor(dueDuration: number, lastReviewed: number): number {
    return (Date.now() - lastReviewed) / dueDuration
}
