import { supabase } from "../api"
import { ReviewRecord } from "../types/study/ReviewRecord"
import { WordStudy } from "../types/study/WordStudy"
import { WordlistContent } from "../types/wordlist/Wordlist"
import { getOverdueFactor, srsUpdate } from "../utils/srsUtils"
import { generateChallenges } from "./ChallengeGenerator"

async function getWordStudyWithChallenges(reviewRecord: ReviewRecord, wordlistContent: WordlistContent) {
    const { challenges, error: challengeError, warnings } = await generateChallenges(wordlistContent)
    if (challengeError)
        return { error: challengeError }

    const wordStudy = {
        wordlistContent: wordlistContent,
        reviewRecord: reviewRecord,
        challenges: challenges
    } as WordStudy
    return { wordStudy, warnings }
}

function sortReviewsMostDueFirst(reviewRecords: ReviewRecord[]) {
    return reviewRecords.sort((a: ReviewRecord, b: ReviewRecord) =>
        getOverdueFactor(b.due_duration_ms, b.last_reviewed) - getOverdueFactor(a.due_duration_ms, a.last_reviewed))
}

function getMostDueReview(reviewRecords: ReviewRecord[]): ReviewRecord | null {
    if (reviewRecords.length < 1)
        return null
    const mostDueReview = sortReviewsMostDueFirst(reviewRecords).find(r => r)

    if (mostDueReview)
        return mostDueReview
    else
        return null
}

async function getNextWordStudy(reviewRecords: ReviewRecord[], wordlistContent: WordlistContent[]) {
    const mostDueReview = getMostDueReview(reviewRecords)
    const mostDueContent = mostDueReview && wordlistContent.find(c => isReviewRecordMatchingContent(mostDueReview, c))

    // TODO: a better check for this?
    if (mostDueReview && !mostDueContent)
        return { error: new Error(`Stray review record found: ${mostDueReview.word_id}, ${mostDueReview.reading_id}, ${mostDueReview.meaning_id}`) }

    if (mostDueReview && mostDueContent && getOverdueFactor(mostDueReview.due_duration_ms, mostDueReview.last_reviewed) >= 1) {
        const { wordStudy, error, warnings } = await getWordStudyWithChallenges(mostDueReview, mostDueContent)
        if (error)
            return { error }

        return { nextWordStudy: wordStudy, warnings }
    }

    const newWord = wordlistContent.find(c => !reviewRecords.find(r => isReviewRecordMatchingContent(r, c)))
    if (!newWord) {
        if (mostDueReview && mostDueContent) {
            // TODO: notification that reviews are done, continue if desired (isStudyCaughtUp hook)
            const { wordStudy, error, warnings } = await getWordStudyWithChallenges(mostDueReview, mostDueContent)
            if (error)
                return { error }

            return { nextWordStudy: wordStudy, warnings }
        }
        else {
            // wordlist is empty
            // TODO: better notification
            return { error: new Error("This wordlist is empty.") }
        }
    }

    const nextWordStudy = {
        wordlistContent: newWord
    } as WordStudy
    return { nextWordStudy }
}

export function isReviewRecordMatchingContent(reviewRecord: ReviewRecord, wordlistContent: WordlistContent) {
    return reviewRecord.word_id === (wordlistContent.dictionary_word?.id ?? null) &&
        reviewRecord.reading_id === wordlistContent.dictionary_reading.id &&
        reviewRecord.meaning_id === wordlistContent.dictionary_meaning.id
}

export function getUpdatedReviewRecord(reviewRecord: ReviewRecord, mistakesCount: number): ReviewRecord {
    const recallDegree = Math.max(0, 1 - (mistakesCount / 10))
    const previous = {
        difficulty: reviewRecord.difficulty,
        dueDuration: reviewRecord.due_duration_ms,
        lastReviewed: reviewRecord.last_reviewed,
    }
    const srsData = srsUpdate(recallDegree, previous)
    return {
        ...reviewRecord,
        difficulty: srsData.difficulty,
        due_duration_ms: srsData.dueDuration,
        last_reviewed: srsData.lastReviewed,
    } as ReviewRecord
}

export function getNewReviewRecord(userId: string, wordId: number | null, readingId: number, meaningId: number): ReviewRecord {
    const srsData = srsUpdate()
    return {
        word_id: wordId,
        reading_id: readingId,
        meaning_id: meaningId,
        difficulty: srsData.difficulty,
        due_duration_ms: srsData.dueDuration,
        last_reviewed: srsData.lastReviewed,
        user_id: userId
    } as ReviewRecord
}

export async function initializeStudy(userId: string, wordlistContent: WordlistContent[], setReviewRecords: (value: ReviewRecord[]) => void) {
    const { data: reviewRecords, error } =
        await supabase.from('review_record_wordlist_content_joined')
            .select("id, user_id, difficulty, due_duration_ms, last_reviewed, word_id, reading_id, meaning_id")
            .eq("user_id", userId)
            .in('wordlist_content_id', wordlistContent.map(c => c.id))
    if (error)
        return { error }

    setReviewRecords(reviewRecords.map(r => r as ReviewRecord))
    return {}
}

export async function advanceStudy(wordlistContent: WordlistContent[], reviewRecords: ReviewRecord[], setWordStudy: (value: WordStudy) => void) {
    const { nextWordStudy, error, warnings } = await getNextWordStudy(reviewRecords, wordlistContent)
    if (error)
        return { error }

    setWordStudy(nextWordStudy)
    return { warnings }
}
