import { useCallback, useEffect, useState } from "react"
import { WordlistListEntry } from "../../components/wordlistview/WordlistEntriesList"
import { WordlistContent } from "../../types/wordlist/Wordlist"

function WordlistContentToListEntries(
    wordlistContent: WordlistContent[],
    deleteItem: (value: WordlistContent) => void
): WordlistListEntry[] {
    return wordlistContent.map(c => ({
        id: c.id,
        index: c.index as number,
        word: c.dictionary_word?.word,
        reading: c.dictionary_reading.reading,
        meaning: c.dictionary_meaning,
        deleteItem: () => deleteItem(c)
    } as WordlistListEntry))
}

//TODO: look into using this hook in the Wordlist List component and using "key" prop (V1f8MOQiHRw 16:53)
export default function useWordlistListEntries(wordlistContent: WordlistContent[] | undefined, deleteItem: (value: WordlistContent) => void) {
    const [wordlistListEntries, setWordlistListEntries] = useState<WordlistListEntry[]>()
    const [isLoading, setIsLoading] = useState<boolean>(true)

    const updateEntriesAsync = useCallback(async (wordlistContent: WordlistContent[]) => {
        if (wordlistContent.length < 1) {
            setIsLoading(false)
            setWordlistListEntries([])
            return
        }

        const updatedListEntries = WordlistContentToListEntries(wordlistContent, deleteItem)
        setWordlistListEntries(updatedListEntries)
        setIsLoading(false)
    }, [deleteItem])

    useEffect(() => {
        if (wordlistContent) {
            updateEntriesAsync(wordlistContent)
        }
    }, [updateEntriesAsync, wordlistContent])

    return { wordlistListEntries, isLoading }
}
