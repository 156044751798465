import { useEffect, useState } from "react";
import { ReviewRecord } from "../../types/study/ReviewRecord";
import { WordlistContent, WordlistLevel } from "../../types/wordlist/Wordlist";
import { StudyInformation } from "../../types/study/StudyInformation";
import { getOverdueFactor } from "../../utils/srsUtils";
import { WordStudy } from "../../types/study/WordStudy";
import { isReviewRecordMatchingContent } from "../../helpers/StudyHelper";

function getTimeSinceMilliseconds(milliseconds: number) {
    var delta = (new Date().getTime() - milliseconds) / 1000
    const days = Math.floor(delta / 86_400)
    delta -= days * 86_400

    const hours = Math.floor(delta / 3600) % 24
    delta -= hours * 3600

    const minutes = Math.floor(delta / 60) % 60
    delta -= minutes * 60

    const seconds = Math.floor(delta % 60)
    const showSeconds = (seconds > 0) || (days < 1 && hours < 1 && minutes < 1)

    return (days > 0 ? `${days}d, ` : "") +
        (hours > 0 ? `${hours}h, ` : "") +
        (minutes > 0 ? `${minutes}m, ` : "") +
        (showSeconds ? `${seconds}s ago` : "")
}

function getMatchingReviewRecord(wordlistContent: WordlistContent, reviewRecords: ReviewRecord[]) {
    return reviewRecords.find(r => isReviewRecordMatchingContent(r, wordlistContent))
}

export default function useStudyInformation(
    reviewRecords: ReviewRecord[] | undefined,
    wordStudy: WordStudy | undefined,
    wordlistLevel: WordlistLevel | undefined,
    mistakes: number
) {
    const [studyInformation, setStudyInformation] = useState<StudyInformation>()

    useEffect(() => {
        if (reviewRecords && wordlistLevel && wordStudy && wordlistLevel.wordlist_content.length > 0) {
            const nonDueWordsCount = reviewRecords.filter(r => getOverdueFactor(r.due_duration_ms, r.last_reviewed) < 1).length
            const dueWordsCount = reviewRecords.filter(r => getOverdueFactor(r.due_duration_ms, r.last_reviewed) >= 1).length
            const newWordsCount = wordlistLevel.wordlist_content.filter(c => !getMatchingReviewRecord(c, reviewRecords)).length
            const currentReviewRecord = wordStudy.reviewRecord
            const activeChallengeWordStatus = currentReviewRecord ?
                getOverdueFactor(currentReviewRecord.due_duration_ms, currentReviewRecord.last_reviewed) >= 1 ? 'dueWord' : 'nonDueWord'
                : 'newWord'
            const lastReviewedText = currentReviewRecord?.last_reviewed ? "(" + getTimeSinceMilliseconds(currentReviewRecord.last_reviewed) + ")" : null

            setStudyInformation({
                nonDueWordsCount: nonDueWordsCount,
                dueWordsCount: dueWordsCount,
                newWordsCount: newWordsCount,
                activeChallengeWordStatus: activeChallengeWordStatus,
                lastReviewedText: lastReviewedText,
                mistakes: mistakes
            })
        }
    }, [mistakes, reviewRecords, wordStudy, wordlistLevel])

    return { studyInformation }
}
