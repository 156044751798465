import { supabase } from '../../api'
import { QueryData } from '@supabase/supabase-js'

export type Wordlist = QueryData<ReturnType<typeof fullWordlistQuery>>[number]
export type WordlistLevel = Wordlist['wordlist_level'][number]
export type WordlistContent = WordlistLevel['wordlist_content'][number]

export const fullWordlistQuery = () => supabase.from('wordlist').select(`
    id,
    name,
    date_created,
    is_public,
    user_id,
    wordlist_level (
        id,
        name,
        index,
        wordlist_content (
            id,
            index,
            dictionary_word (
                id,
                word,
                index
            ),
            dictionary_reading!inner (
                id,
                reading,
                index
            ),
            dictionary_meaning!inner (
                id,
                dictionary_meaning_translation (
                    id,
                    translation,
                    index
                ),
                index
            )
        )
    )
`)
