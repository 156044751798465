import { useNavigate, useParams } from 'react-router-dom'
import CompletedChallenge from "../components/study/CompletedChallenge"
import { Box, Container } from "@mui/material"
import StandaloneChallenge from "../components/study/StandaloneChallenge"
import { ChallengeData, PrimitivesChallengeData, StandaloneChallengeData } from "../types/study/ChallengeData"
import PartialWordPreview from "../components/study/PartialWordPreview"
import ErrorBackdrop from "../components/study/ErrorBackdrop"
import useStudy from "../hooks/study/useStudy"
import PrimitivesChallenge from "../components/study/PrimitivesChallenge"
import { StudyContext } from "../contexts/StudyContext"
import { SessionContext } from '../contexts/SessionContext'
import { useContext } from 'react'
import Loadable from '../components/Loadable'
import Subheader from '../components/Subheader'
import StudyInformationDisplay from '../components/study/StudyInformationDisplay'
import { WordlistContent } from '../types/wordlist/Wordlist'
import { Translation } from '../components/study/TranslationView'

function renderMainContent(wordlistContent: WordlistContent, charactersShown: number, challenge?: ChallengeData) {
    const word = wordlistContent.dictionary_word?.word ?? null
    const reading = wordlistContent.dictionary_reading.reading
    const translations = wordlistContent.dictionary_meaning.dictionary_meaning_translation.map(t => ({ text: t.translation, index: t.index } as Translation))
    const primitivesChallenge = challenge as PrimitivesChallengeData
    if (primitivesChallenge && primitivesChallenge.primitives) {
        return (
            <PrimitivesChallenge translations={translations} challenge={primitivesChallenge} >
                <PartialWordPreview word={word} reading={reading} charactersShown={charactersShown} />
            </PrimitivesChallenge>
        )
    }

    const standaloneChallenge = challenge as StandaloneChallengeData
    if (standaloneChallenge) {
        return (
            <StandaloneChallenge translations={translations} challenge={standaloneChallenge}>
                <PartialWordPreview word={word} reading={reading} charactersShown={charactersShown} />
            </StandaloneChallenge>
        )
    }

    return (<CompletedChallenge word={word} reading={reading} translations={translations} />)
}

export default function Study() {
    const params = useParams()
    const navigate = useNavigate()
    const user = useContext(SessionContext).session.user
    const { wordlist, wordlistContent, challenge, charactersShown, backdropOpen, studyInformation, onMistake, onContinue, setBackdropClosed, isLoading, error } =
        useStudy(params.listId, user.id)

    return (
        <StudyContext.Provider value={{ mistakeAction: onMistake, continueAction: onContinue }}>
            <Subheader title={`${wordlist?.name ?? ""}`} backAction={() => navigate(-1)} />
            <Container>
                {backdropOpen && (<ErrorBackdrop onFinished={setBackdropClosed} />)}
                <Box margin="20px 0px">
                    <Loadable isLoading={isLoading} error={error}>
                        <Box display='flex' flexDirection='column' rowGap={1}>
                            {studyInformation && <StudyInformationDisplay studyInformation={studyInformation} />}
                            {wordlistContent && renderMainContent(wordlistContent, charactersShown, challenge)}
                        </Box>
                    </Loadable>
                </Box>
            </Container>
        </StudyContext.Provider>
    )
}
