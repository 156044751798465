import { Box, List, ListItem, Modal, Typography } from "@mui/material";
import { Translation } from "./TranslationView";

export default function TranslationsModal({ translations, open, onClose }: { translations: Translation[], open: boolean, onClose: () => void }) {
    return <Modal open={open} onClose={onClose}>
        <Box sx={style} display="flex" >
            <List sx={{ listStyleType: 'disc', paddingX: 2 }}>
                {translations.map(t =>
                    <ListItem sx={{ display: 'list-item' }}>
                        <Typography>{t.text}</Typography>
                    </ListItem>
                )}
            </List>
        </Box>
    </Modal>
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
}
