import { useCallback, useEffect, useState } from "react"
import { DraggableData, Positions } from "../../types/study/PrimitiveChallengeTypes"
import { Primitive } from "../../types/study/ChallengeData"
import { insertRandomly } from "../../utils/arrayUtils"

export default function usePrimitivesChallenge(primitives: Primitive[], falseChars: number[], continueAction: () => void, mistakeAction: () => void) {
    const [displayedPositions, setDisplayedPositions] = useState<Positions[]>([])
    const [draggables, setDraggables] = useState<DraggableData[]>([])

    useEffect(() => {
        const initialDraggables = falseChars.map(c => ({ id: `${c}false`, primitive: c } as DraggableData))
        primitives.forEach(p => {
            insertRandomly(initialDraggables, { id: `${p.char}${p.position}`, primitive: p.char, primitivePosition: p.position } as DraggableData)
        })
        setDraggables(initialDraggables)
    }, [primitives, falseChars])

    useEffect(() => {
        if (displayedPositions.length >= primitives.length) {
            setDisplayedPositions([])
            continueAction()
        }
    }, [continueAction, displayedPositions, primitives])

    const dropPrimitive = useCallback((draggableData: DraggableData, droppedPosition: Positions, expectedChar: number | null) => {
        if (draggableData.primitive === expectedChar) {
            setDisplayedPositions(prev => [...prev, droppedPosition])
            setDraggables(prev => prev.filter(d => d.id !== draggableData.id))
        }
        else {
            mistakeAction()
        }
    }, [mistakeAction])

    return { displayedPositions, dropPrimitive, draggables }
}
