import { useCallback, useEffect, useState } from "react"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"
import useWordlistSnapshots from "./useWordlistSnapshots"
import useWordlistSubscriptions from "./useWordlistSubscriptions"
import { SelectChangeEvent } from "@mui/material"
import { useSearchParams } from "react-router-dom"

type FilterSelectOptions = 'all' | 'subscribed' | 'user-created' | undefined

export default function useBrowseWordlists() {
    const [params, setParams] = useSearchParams()
    const searchString = params.get("search") ?? undefined
    const filter = params.get("filter") as FilterSelectOptions
    const [filterSelectValue, setFilterSelectValue] = useState<FilterSelectOptions>(filter)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { wordlistSnapshots, setWordlistSnapshots, error: wordlistSnapshotsFetchError, getTotalWordCount, viewWordlist } =
        useWordlistSnapshots(filterSelectValue === 'subscribed', filterSelectValue !== 'user-created', filterSelectValue === 'user-created', 30, searchString)
    const { subscribe, unsubscribe } = useWordlistSubscriptions(setWordlistSnapshots)
    const { handleError } = useError()
    const [error, setError] = useState<PostgrestError>()

    useEffect(() => {
        if (wordlistSnapshotsFetchError) {
            handleError(wordlistSnapshotsFetchError)
            setError(wordlistSnapshotsFetchError)
            setIsLoading(false)
        }
    }, [wordlistSnapshotsFetchError, handleError])

    useEffect(() => {
        if (wordlistSnapshots) {
            setIsLoading(false)
        }
    }, [wordlistSnapshots])

    const handleFilterChange = useCallback((event: SelectChangeEvent) => {
        const newValue = event.target.value as FilterSelectOptions
        setFilterSelectValue(newValue)

        const newParams = new URLSearchParams(params)
        if (newValue) {
            newParams.set("filter", newValue)
        }
        else {
            newParams.delete("filter")
        }

        setParams(newParams)
    }, [params, setParams])

    return {
        wordlistSnapshots,
        setWordlistSnapshots,
        isLoading,
        error,
        viewWordlist,
        getTotalWordCount,
        subscribe,
        unsubscribe,
        filterSelectValue,
        handleFilterChange,
        searchString
    }
}
