import { Box, Button, Typography } from '@mui/material'
import { StandaloneChallengeData } from '../../types/study/ChallengeData'
import useStandaloneChallenge from '../../hooks/study/useStandaloneChallenge'
import { useContext } from 'react'
import { StudyContext } from '../../contexts/StudyContext'
import TranslationView, { Translation } from './TranslationView'

function renderButton(char: number, action: () => void) {
    return (
        <Button key={char} variant="outlined" onClick={action}>
            <Typography variant="h3" padding="15px" color="text.primary" >{String.fromCodePoint(char)}</Typography>
        </Button>
    )
}

export default function StandaloneChallenge(
    { translations, challenge, children }: { translations: Translation[], challenge: StandaloneChallengeData, children: JSX.Element }
) {
    const studyContext = useContext(StudyContext)
    const { buttons } = useStandaloneChallenge(challenge.char, challenge.falseChars, studyContext.continueAction, studyContext.mistakeAction)

    return (
        <Box display="flex" flexDirection="column" rowGap="40px">
            {children}
            <TranslationView translations={translations} />
            <Box display="flex" flexDirection="row" flex={1} flexWrap="wrap" justifyContent="center" columnGap="20px" >
                {buttons.map(b => renderButton(b.char, b.action))}
            </Box>
        </Box>
    )
}
