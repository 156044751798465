import React from "react"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import WordSearchboxSuggestion from "./WordSearchboxSuggestion"
import useWordSearch from "../../hooks/wordlist/useWordSearch"
import { DictionaryTranslationSearchResult, DictionaryWordSearchResult } from "../../types/dictionary/DictionarySearchResult"

export default function WordSearchbox(
    { selectWord }: { selectWord: (word: DictionaryTranslationSearchResult | DictionaryWordSearchResult) => void }
) {
    const { isLoading, searchOptions, inputValue, setInputValue } = useWordSearch()

    return (
        <Autocomplete
            sx={{ width: 500 }}
            freeSolo
            options={searchOptions}
            value={inputValue}
            noOptionsText="No words found."
            filterOptions={(x) => x}
            getOptionLabel={o => typeof o === 'string' ? o : o.id?.toString() ?? ""}
            onInputChange={(_e: any, newInputValue: string | DictionaryTranslationSearchResult | DictionaryWordSearchResult) => {
                // TODO: on selecting a suggestion, the key (dictionary id) is being passed here as a string
                // and triggering the fetch before inputValue gets set back to ""
                const newStringValue = newInputValue as string
                if (newStringValue)
                    setInputValue(newStringValue)
            }}
            onChange={async (_event: any, newValue: string | DictionaryTranslationSearchResult | DictionaryWordSearchResult | null) => {
                // I hate typescript so much
                if (newValue && typeof newValue !== 'string') {
                    selectWord(newValue)
                }
                setInputValue("")
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Search input"
                    fullWidth sx={{ backgroundColor: "grey.50" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? < CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            renderOption={(props, option) => {
                if (typeof option === 'string')
                    return
                const dictionarySearchResult = option
                if (dictionarySearchResult) {
                    const { key, ...optionProps } = { ...props, key: dictionarySearchResult.id }
                    return (
                        <WordSearchboxSuggestion key={key} dictionarySearchResult={dictionarySearchResult} props={optionProps} />
                    )
                }
            }
            }
        />)
}
