import Kanji from "./Kanji"
import DropArea from "./DropArea"
import { Box } from "@mui/material"
import { Positions } from "../../types/study/PrimitiveChallengeTypes"
import { Primitive } from "../../types/study/ChallengeData"
import { checkPositionMatch } from "../../helpers/PrimitivePositionHelper"

export default function DropAreaContainer(
    { kanjiSvgString, shownPositions, primitives }: { kanjiSvgString: string | undefined, shownPositions: Positions[], primitives: Primitive[] }
) {
    const leftChar = primitives.find(p => checkPositionMatch(p.position, Positions.LEFT))?.char ?? null
    const rightChar = primitives.find(p => checkPositionMatch(p.position, Positions.RIGHT))?.char ?? null
    const topChar = primitives.find(p => checkPositionMatch(p.position, Positions.TOP))?.char ?? null
    const bottomChar = primitives.find(p => checkPositionMatch(p.position, Positions.BOTTOM))?.char ?? null
    return (
        <Box height="300px" width="300px" position="relative" bgcolor="common.white" alignSelf="center" border="1px solid" borderColor="grey.500">
            <Kanji svgString={kanjiSvgString} shownPositions={shownPositions} showStrokeNumbers={false} />
            <Box>
                <DropArea thisPosition={Positions.LEFT} expectedChar={leftChar} clipPath="polygon(0% 0%, 50% 50%, 0% 100%)" />
                <DropArea thisPosition={Positions.TOP} expectedChar={topChar} clipPath="polygon(0% 0%, 100% 0%, 50% 50%)" />
                <DropArea thisPosition={Positions.BOTTOM} expectedChar={bottomChar} clipPath="polygon(0% 100%, 50% 50%, 100% 100%)" />
                <DropArea thisPosition={Positions.RIGHT} expectedChar={rightChar} clipPath="polygon(100% 0%, 50% 50%, 100% 100%)" />
            </Box>
        </Box>
    )
}
