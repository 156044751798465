import { Box, ListItem, ListItemText, Typography } from "@mui/material";
import { WordlistListEntry } from "./WordlistEntriesList"
import { Draggable } from 'react-beautiful-dnd'
import WordlistItemOptionsButtonMenu from "./WordlistItemOptionsButtonMenu";

type WordlistEntriesListItemProps = {
    wordlistListEntry: WordlistListEntry
    editMode: boolean
}

export default function WordlistEntriesListItem({ wordlistListEntry, editMode }: WordlistEntriesListItemProps) {
    const { word, reading, meaning } = wordlistListEntry

    const primaryText = word ?? reading
    const secondaryText = word ? reading : undefined
    const translations = meaning.dictionary_meaning_translation.map(t => t.translation).join(", ")
    const even = wordlistListEntry.index % 2 === 0

    const listItemContent = (
        <Box bgcolor={even ? "action.hover" : undefined} display="flex" flexDirection="row" minWidth={900}>
            <Typography alignSelf="center" textAlign="center" width={50} >{wordlistListEntry.index + 1}</Typography>
            <ListItemText primary={primaryText} secondary={secondaryText} />
            <Typography alignSelf="center" width={400} >{translations}</Typography>
            {
                editMode &&
                <WordlistItemOptionsButtonMenu deleteItem={wordlistListEntry.deleteItem} />
            }
        </Box>)

    return (
        <Box maxWidth={500}>
            {editMode ?
                <Draggable
                    draggableId={wordlistListEntry.word + wordlistListEntry.reading + wordlistListEntry.meaning.dictionary_meaning_translation}
                    index={wordlistListEntry.index}
                >
                    {(provided) => (
                        <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            {listItemContent}
                        </ListItem>
                    )}
                </Draggable >
                :
                listItemContent
            }
        </Box>
    )
}
