import { Box } from '@mui/material'
import { useDrop } from 'react-dnd'
import { DraggableData, Positions } from '../../types/study/PrimitiveChallengeTypes'
import { useContext } from 'react'
import { PrimitivesChallengeContext } from '../../contexts/PrimitivesChallengeContext'

export default function DropArea({ clipPath, thisPosition, expectedChar }: { clipPath: string, thisPosition: Positions, expectedChar: number | null }) {
    const handleDrop = useContext(PrimitivesChallengeContext).handleDrop

    const [collectedProps, drop] = useDrop(() => ({
        accept: 'draggable',
        drop: (item: DraggableData) => {
            handleDrop(item, thisPosition, expectedChar)
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
    }), [thisPosition, expectedChar])

    const backgroundColor = collectedProps.isOver ? "action.focus" : "#00000000"
    return (
        <Box position="absolute" width="100%" height="100%" bgcolor={backgroundColor} sx={{ clipPath: clipPath }} ref={drop} />
    )
}
