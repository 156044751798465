import { useContext } from "react";
import { WordlistSnapshot as WordlistSnapshotData } from "../../types/wordlist/WordlistSnapshot";
import WordlistSnapshot from "./WordlistSnapshot";
import { SessionContext } from "../../contexts/SessionContext";
import WordlistStudyButton from "./WordlistStudyButton";
import WordlistSubscribeButton from "./WordlistSubscribeButton";
import { Stack } from "@mui/material";

type WordlistSnapshotListProps = {
    wordlistSnapshots: WordlistSnapshotData[]
    getTotalWordCount: (wordlistSnapshot: WordlistSnapshotData) => number
    viewWordlist: (wordlistId: string) => void
    setWordlistSnapshots: (value: (value: WordlistSnapshotData[] | undefined) => WordlistSnapshotData[] | undefined) => void
    subscribe: ((wordlistId: string) => Promise<void>) | null
    unsubscribe: ((wordlistId: string) => Promise<void>) | null
}

export default function WordlistSnapshotList(
    { wordlistSnapshots, getTotalWordCount, viewWordlist, setWordlistSnapshots, subscribe, unsubscribe }: WordlistSnapshotListProps
) {
    const { session } = useContext(SessionContext)

    return <Stack rowGap={2}>
        {wordlistSnapshots.map(s => {
            const isOwner = s.user_id === session.user.id
            const onRemove = isOwner ?
                () => setWordlistSnapshots(prev => prev?.filter(p => p.id !== s.id) ?? []) : null
            return <WordlistSnapshot key={s.id} wordlistSnapshot={s} wordCount={getTotalWordCount(s)} viewWordlist={viewWordlist} onRemove={onRemove} >
                {subscribe && unsubscribe ?
                    <WordlistSubscribeButton
                        wordlistId={s.id}
                        isSubscribed={s.wordlist_subscription.length > 0}
                        subscribe={subscribe}
                        unsubscribe={unsubscribe}
                    /> :
                    <WordlistStudyButton wordlistId={s.id} />
                }
            </WordlistSnapshot>
        })}
    </Stack>
}
